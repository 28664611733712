import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import memberReducer from "../features/Member/memberSlice";
import statisticReducer from "../features/Statistic/StatisticSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    member: memberReducer,
    statistic: statisticReducer
  },
});
