import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getDetailMember, getListMember, getNutritionClub } from "./memberAPI";

const initialState = {
    list: [],
    nutritionClub: [],
    detailMember: undefined,
    totalMember: undefined,
    status: 'idle',
    error: undefined,
};

export const getMembersAsync = createAsyncThunk(
    'members',
    async (params, thunkAPI) => {
        try {
            const response = await getListMember(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getNutritionClubAsync = createAsyncThunk(
    'nutritionClub',
    async (params, thunkAPI) => {
        try {
            const response = await getNutritionClub(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getDetailMemberAsync = createAsyncThunk(
    'detailMember',
    async (params, thunkAPI) => {
        try {
            const response = await getDetailMember(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNutritionClub: state => {
            state.nutritionClub = undefined;
        },
        clearMembers: state => {
            state.list = undefined;
        },
        clearDetailMember: state => {
            state.detailMember = undefined;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // itopicluding actions generated by createAsytopicThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getMembersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMembersAsync.fulfilled, (state, action) => {
                state.list = action.payload.data
                state.totalMember = action.payload.total_count
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getMembersAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getMembers"
                    }
                }
            })
            .addCase(getNutritionClubAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNutritionClubAsync.fulfilled, (state, action) => {
                state.nutritionClub = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNutritionClubAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNutritionClub"
                    }
                }
            })
            .addCase(getDetailMemberAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDetailMemberAsync.fulfilled, (state, action) => {
                state.detailMember = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getDetailMemberAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getDetailMember"
                    }
                }
            })
    },
});


export const selectMembers = state => state.member.list
export const selectNutritionClub = state => state.member.nutritionClub
export const selectMemberDetail = state => state.member.detailMember
export const totalMember = state => state.member.totalMember
export const memberStatus = state => state.member.status
export const memberError = state => state.member.error

export const { clearMemberDetail, clearMembers, setIdleStatus, clearNutritionClub, clearDetailMember } = memberSlice.actions;


export default memberSlice.reducer;
