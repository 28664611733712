import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getStatisticChartDetail = (params) => {
  return axiosClient.get("statistic/chart-detail/" + params.nutritionClubId, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getStatisticRepresentativeMember = (params) => {
  return axiosClient.get("statistic/representative-ncc", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getStatisticRepresentativeCustomer = (params) => {
  return axiosClient.get("statistic/representative-customer", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getStatisticSoldPackages= (params) => {
    return axiosClient.get("statistic/sold-packages", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };

  const getStatisticChartMember = (params) => {
    return axiosClient.get("statistic/chart-member/" + params.nutritionClubId, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };
  const getStatisticBirthdayInMonth= (params) => {
    return axiosClient.get("statistic/birthday-in-month", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };
  const getStatisticMembersAlmostExpiredPackage= (params) => {
    return axiosClient.get("statistic/members-almost-expired-package", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };
  const getStatisticRepresentativePackagesProducts= (params) => {
    return axiosClient.get("statistic/representative-packages-products", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };

  const getStatisticRepresentativeHealthAmbassador= (params) => {
    return axiosClient.get("statistic/representative-health-ambassador", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };
  const getStatisticNoticeableMembers= (params) => {
    return axiosClient.get("statistic/noticeable-members", {
      params,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  };
export {
  getStatisticChartDetail,
  getStatisticRepresentativeMember,
  getStatisticRepresentativeCustomer,
  getStatisticSoldPackages,
  getStatisticChartMember,
  getStatisticBirthdayInMonth,
  getStatisticMembersAlmostExpiredPackage,
  getStatisticRepresentativePackagesProducts,
  getStatisticRepresentativeHealthAmbassador,
  getStatisticNoticeableMembers
};
