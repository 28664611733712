import axiosClient from "../axiosClient";

const login = (data) => {
  return axiosClient.post("authentication/login", data);
};

const changePassword = (data) => {
  return axiosClient.post("authentication/change-password", data, {
    headers: {
      Authorization:
        "Bearer " + localStorage?.auth &&
        JSON.parse(localStorage.auth)?.access_token,
    },
  });
};

const logout = () => {
  return axiosClient.post(
    `authentication/logout?token=${
      localStorage?.auth && JSON.parse(localStorage.auth)?.access_token
    }`
  );
};

const getOTP = (email) => {
  return axiosClient.post("authentication/reset-password" + `?email=${email}`);
};

const checkOTP = (data) => {
  return axiosClient.post("authentication/login-with-otp", data);
};

const resetPassword = (data, access_token) => {
  return axiosClient.post("authentication/confirm-and-change-password", data, {
    headers: {
      Authorization: "Bearer " + access_token,
    },
  });
};

export { login, resetPassword, changePassword, logout, getOTP, checkOTP };
