import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) || undefined;
}

const getListMember = (params) => {
    return axiosClient.get("member", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getDetailMember = (params) => {
    return axiosClient.get("member/"+params?.MemberId, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getNutritionClub = (params) => {
    return axiosClient.get("nutrition-club", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}



export {
    getListMember, getNutritionClub, getDetailMember
}