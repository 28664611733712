import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Spin } from "antd";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./containers/Login"));
const ForgetPassword = React.lazy(() => import("./containers/ForgetPassword"));
const ListNCO = React.lazy(() => import("./containers/ListNCO/ListNCO"));
const DetailNCO = React.lazy(() => import("./containers/ListNCO/DetailNCO"));
const ReportNCO = React.lazy(() => import("./containers/ListNCO/ReportNCO"));

function App() {
  return (
    <Suspense
      fallback={
        <div className="loading-component">
          <Spin size="large" tip="Loading..." />
        </div>
      }
    >
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/forget-password/:step"
            component={ForgetPassword}
          />
          <Route exact path="/nco-list" component={ListNCO} />
          <Route exact path="/nco-detail/:id" component={DetailNCO} />
          <Route exact path="/nco-report/:id" component={ReportNCO} />
          <Route path="/:locale" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
