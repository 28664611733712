import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getStatisticBirthdayInMonth,
  getStatisticChartDetail,
  getStatisticChartMember,
  getStatisticMembersAlmostExpiredPackage,
  getStatisticNoticeableMembers,
  getStatisticRepresentativeCustomer,
  getStatisticRepresentativeHealthAmbassador,
  getStatisticRepresentativeMember,
  getStatisticRepresentativePackagesProducts,
  getStatisticSoldPackages,
} from "./StatisticAPI";

const initialState = {
  chartDetail: undefined,
  chartMember: undefined,
  representativeMember: undefined,
  representativeCustomer: undefined,
  birthdayInMonth: undefined,
  noticeableMembers: undefined,
  representativePackagesProducts: undefined,
  membersAlmostExpiredPackage: undefined,
  representativeHealthAmbassador: undefined,
  soldPackages: undefined,
  status: "idle",
  error: undefined,
};

export const getStatisticChartDetailAsync = createAsyncThunk(
  "getStatisticChartDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticChartDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticRepresentativeMemberAsync = createAsyncThunk(
  "getStatisticRepresentativeMember",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticRepresentativeMember(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticRepresentativeCustomerAsync = createAsyncThunk(
  "getStatisticRepresentativeCustomer",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticRepresentativeCustomer(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStatisticSoldPackagesAsync = createAsyncThunk(
  "getStatisticSoldPackages",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticSoldPackages(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticChartMemberAsync = createAsyncThunk(
  "getStatisticChartMember",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticChartMember(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticBirthdayInMonthAsync = createAsyncThunk(
  "getStatisticBirthdayInMonth",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticBirthdayInMonth(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticMembersAlmostExpiredPackageAsync = createAsyncThunk(
  "getStatisticMembersAlmostExpiredPackage",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticMembersAlmostExpiredPackage(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticRepresentativePackagesProductsAsync = createAsyncThunk(
  "getStatisticRepresentativePackagesProducts",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticRepresentativePackagesProducts(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStatisticNoticeableMembersAsync = createAsyncThunk(
  "getStatisticNoticeableMembers",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticNoticeableMembers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStatisticRepresentativeHealthAmbassadorAsync = createAsyncThunk(
  "getStatisticRepresentativeHealthAmbassador",
  async (params, thunkAPI) => {
    try {
      const response = await getStatisticRepresentativeHealthAmbassador(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearChartDetail: (state) => {
      state.chartDetail = undefined;
    },
    clearChartMember: (state) => {
      state.chartDetail = undefined;
    },
    clearRepresentativeMember: (state) => {
      state.representativeMember = undefined;
    },
    clearRepresentativeCustomer: (state) => {
      state.representativeMember = undefined;
    },
    clearSoldPackages: (state) => {
      state.soldPackages = undefined;
    },
    clearBirthdayInMonth: (state) => {
      state.birthdayInMonth = undefined;
    },
    clearMembersAlmostExpiredPackage: (state) => {
      state.membersAlmostExpiredPackage = undefined;
    },
    clearRepresentativePackagesProducts: (state) => {
      state.representativePackagesProducts = undefined;
    },
    clearRepresentativeHealthAmbassador: (state) => {
      state.representativeHealthAmbassador = undefined;
    },
    clearNoticeableMembers: (state) => {
      state.noticeableMembers = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // itopicluding actions generated by createAsytopicThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticChartDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticChartDetailAsync.fulfilled, (state, action) => {
        state.chartDetail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStatisticChartDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getMembers",
          };
        }
      })
      .addCase(getStatisticChartMemberAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticChartMemberAsync.fulfilled, (state, action) => {
        state.chartMember = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStatisticChartMemberAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getMembers",
          };
        }
      })
      .addCase(getStatisticRepresentativeMemberAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getStatisticRepresentativeMemberAsync.fulfilled,
        (state, action) => {
          state.representativeMember = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticRepresentativeMemberAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getRepresentativeMember",
            };
          }
        }
      )
      .addCase(getStatisticRepresentativeCustomerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getStatisticRepresentativeCustomerAsync.fulfilled,
        (state, action) => {
          state.representativeCustomer = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticRepresentativeCustomerAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getRepresentativeCustomer",
            };
          }
        }
      )
      .addCase(getStatisticSoldPackagesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticSoldPackagesAsync.fulfilled, (state, action) => {
        state.soldPackages = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStatisticSoldPackagesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getSoldPackages",
          };
        }
      })
      .addCase(getStatisticBirthdayInMonthAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticBirthdayInMonthAsync.fulfilled, (state, action) => {
        state.birthdayInMonth = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStatisticBirthdayInMonthAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getBirthdayInMonth",
          };
        }
      })
      .addCase(
        getStatisticMembersAlmostExpiredPackageAsync.pending,
        (state) => {
          state.status = "loading";
        }
      )
      .addCase(
        getStatisticMembersAlmostExpiredPackageAsync.fulfilled,
        (state, action) => {
          state.membersAlmostExpiredPackage = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticMembersAlmostExpiredPackageAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getMembersAlmostExpiredPackage",
            };
          }
        }
      )
      .addCase(
        getStatisticRepresentativePackagesProductsAsync.pending,
        (state) => {
          state.status = "loading";
        }
      )
      .addCase(
        getStatisticRepresentativePackagesProductsAsync.fulfilled,
        (state, action) => {
          state.representativePackagesProducts = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticRepresentativePackagesProductsAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getRepresentativePackagesProducts",
            };
          }
        }
      )
      .addCase(
        getStatisticRepresentativeHealthAmbassadorAsync.pending,
        (state) => {
          state.status = "loading";
        }
      )
      .addCase(
        getStatisticRepresentativeHealthAmbassadorAsync.fulfilled,
        (state, action) => {
          state.representativeHealthAmbassador = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticRepresentativeHealthAmbassadorAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getBirthdayInMonth",
            };
          }
        }
      )
      .addCase(
        getStatisticNoticeableMembersAsync.pending,
        (state) => {
          state.status = "loading";
        }
      )
      .addCase(
        getStatisticNoticeableMembersAsync.fulfilled,
        (state, action) => {
          state.noticeableMembers = action.payload;
          state.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStatisticNoticeableMembersAsync.rejected,
        (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getNoticeableMembers",
            };
          }
        }
      );
  },
});

export const selectStatisticChartDetail = (state) =>
  state.statistic.chartDetail;
export const selectStatisticChartMember = (state) =>
  state.statistic.chartMember;
export const selectStatisticRepresentativeMember = (state) =>
  state.statistic.representativeMember;
export const selectStatisticRepresentativeCustomer = (state) =>
  state.statistic.representativeCustomer;
export const selectStatisticSoldPackages = (state) =>
  state.statistic.soldPackages;
export const selectStatisticBirthdayInMonth = (state) =>
  state.statistic.birthdayInMonth;
export const selectMembersAlmostExpiredPackage = (state) =>
  state.statistic.membersAlmostExpiredPackage;
export const selectRepresentativePackagesProducts = (state) =>
  state.statistic.representativePackagesProducts;
export const selectRepresentativeHealthAmbassador = (state) =>
  state.statistic.representativeHealthAmbassador;
export const selectNoticeableMembers = (state) =>
  state.statistic.noticeableMembers;
export const statisticStatus = (state) => state.statistic.status;
export const statisticError = (state) => state.statistic.error;

export const {
  clearChartDetail,
  setIdleStatus,
  clearRepresentativeMember,
  clearRepresentativeCustomer,
  clearSoldPackages,
  clearChartMember,
  clearBirthdayInMonth,
  clearMembersAlmostExpiredPackage,
  clearRepresentativePackagesProducts,
  clearRepresentativeHealthAmbassador,
  clearNoticeableMembers,
} = statisticSlice.actions;

export default statisticSlice.reducer;
